var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "leaderboard-table__container" },
    [
      _c("BaseIcon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isRightArrowVisible,
            expression: "isRightArrowVisible",
          },
        ],
        staticClass: "leaderboard-table__icon",
        attrs: { name: "leaderboard-arrow" },
      }),
      _c("BaseIcon", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isLeftArrowVisible,
            expression: "isLeftArrowVisible",
          },
        ],
        staticClass: "leaderboard-table__icon leaderboard-table__icon--rotate",
        attrs: { name: "leaderboard-arrow" },
      }),
      _c("AnimatedBorderBlock", { staticClass: "leaderboard-table__wrapper" }, [
        _c("table", { ref: "table", staticClass: "leaderboard-table" }, [
          _c("thead", { staticClass: "leaderboard-table__head" }, [
            _c("tr", { staticClass: "leaderboard-table__header-row" }, [
              _c("th", {
                staticClass:
                  "leaderboard-table__header-cell leaderboard-table__header-cell--first",
              }),
              _c("th", { staticClass: "leaderboard-table__header-cell" }, [
                _vm._v(" Fights "),
              ]),
              _c("th", { staticClass: "leaderboard-table__header-cell" }, [
                _vm._v(" Win "),
              ]),
              _c("th", { staticClass: "leaderboard-table__header-cell" }, [
                _vm._v(" Loss "),
              ]),
              _c("th", { staticClass: "leaderboard-table__header-cell" }, [
                _vm._v(" WinRate "),
              ]),
              _c("th", { staticClass: "leaderboard-table__header-cell" }, [
                _vm._v(" XP (f2g) "),
              ]),
            ]),
          ]),
          _c(
            "tbody",
            { staticClass: "leaderboard-table__body" },
            _vm._l(
              _vm.isLoading ? [] : _vm.rows.leaderboard,
              function (row, index) {
                return _c(
                  "tr",
                  { key: index, staticClass: "leaderboard-table__row" },
                  [
                    _c(
                      "th",
                      {
                        staticClass:
                          "leaderboard-table__cell leaderboard-table__cell--header",
                      },
                      [
                        _c("div", { staticClass: "leaderboard-table__place" }, [
                          _vm._v(_vm._s(index + 1)),
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "leaderboard-table__fighter-image-wrap",
                          },
                          [
                            _c("img", {
                              staticClass: "leaderboard-table__fighter-image",
                              attrs: { src: `${row?.image}` },
                            }),
                          ]
                        ),
                        _c(
                          "p",
                          {
                            staticClass:
                              "leaderboard-table__cell-text leaderboard-table__cell-text--user",
                          },
                          [_vm._v(_vm._s(row?.name))]
                        ),
                      ]
                    ),
                    _c("td", { staticClass: "leaderboard-table__cell" }, [
                      _c(
                        "span",
                        { staticClass: "leaderboard-table__cell-text" },
                        [_vm._v(_vm._s(row?.fights))]
                      ),
                    ]),
                    _c("td", { staticClass: "leaderboard-table__cell" }, [
                      _c(
                        "span",
                        { staticClass: "leaderboard-table__cell-text" },
                        [_vm._v(_vm._s(row?.win))]
                      ),
                    ]),
                    _c("td", { staticClass: "leaderboard-table__cell" }, [
                      _c(
                        "span",
                        { staticClass: "leaderboard-table__cell-text" },
                        [_vm._v(_vm._s(row?.loss))]
                      ),
                    ]),
                    _c("td", { staticClass: "leaderboard-table__cell" }, [
                      _c(
                        "span",
                        { staticClass: "leaderboard-table__cell-text" },
                        [_vm._v(_vm._s(row?.win_rate) + "%")]
                      ),
                    ]),
                    _c("td", { staticClass: "leaderboard-table__cell" }, [
                      _c("div", { staticClass: "leaderboard-table__xp" }, [
                        _c(
                          "span",
                          { staticClass: "leaderboard-table__cell-text" },
                          [_vm._v(_vm._s(row?.total_xp))]
                        ),
                      ]),
                    ]),
                  ]
                )
              }
            ),
            0
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }