<template>
  <div class="leaderboard-event">
    <div>
        <LeaderboardPlayersPosition v-if="rows?.leaderboard_type === 'wallet' && rows.player_position" :position="rows.player_position" />
        <LeaderboardPlayersPositionNFT v-else-if="rows?.leaderboard_type === 'nft' && rows.nfts_position" :positions="rows.nfts_position" />
    </div>
    <div class="leaderboard-event__table-wrap">
        <div class="leaderboard-event__table" v-if="rows?.leaderboard?.length">
            <LeaderboardTableNFT v-if="rows?.leaderboard_type === 'nft'" :rows="rows" :isLoading="isLoading" />
            <LeaderboardTable v-else :rows="rows" :isLoading="isLoading" />
        </div>
        <p class="leaderboard-event__no-data" v-else>There are no players in the leaderboard yet. Hurry up to be the first!</p>
        <EventInfo class="leaderboard-event__info" :event="event"/>
    </div>
  </div>
</template>

<script>
import LeaderboardPlayersPosition from '@/components/leaderboard/LeaderboardPlayersPosition.vue';
import LeaderboardPlayersPositionNFT from '@/components/leaderboard/LeaderboardPlayersPositionNFT.vue';
import LeaderboardTable from '@/components/leaderboard/LeaderboardTable.vue';
import LeaderboardTableNFT from '@/components/leaderboard/LeaderboardTableNFT.vue';
import EventInfo from '@/components/events/EventInfo.vue';

export default {
    name: 'LeaderboardEvent',
    components: {
        LeaderboardPlayersPosition,
        LeaderboardPlayersPositionNFT,
        LeaderboardTable,
        LeaderboardTableNFT,
        EventInfo
    },
    props: {
        rows: {
            type: Object,
            default: () => {}
        },
        event: {
            type: Object,
            default: () => {}
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.leaderboard-event {
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    flex-direction: column;


    &__info {
        position: relative;
        right: 0;
        order: -1;
    }

    &__table {
        max-width: unset;
    }

    &__player-position {
        order: -1;
        margin-left: auto;
    }

    &__table-wrap {
        display: flex;
        flex-direction: column;
        gap: 45px;
    }

    &__no-data {
        font-family: "Tektur";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        color: #ffffff;
    }
}

@media screen and (max-width: 1023px) {
    .leaderboard-event {
        flex-direction: column;

        &__info {
            position: relative;
            right: 0;
            order: -1;
        }

        &__table {
            max-width: unset;
        }

        &__player-position {
            order: -1;
            margin-left: auto;
        }

        &__table-wrap {
            display: flex;
            flex-direction: column;
            gap: 45px;
        }

        &__no-data {
            font-size: 18px;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@media screen and (max-width: ($bp_mb - 1px)) {
    .leaderboard-event {
        padding: 0 !important;

        &__no-data {
            font-size: 16px;
            padding-left: 15px;
            padding-right: 15px;
        }
    }
}

</style>