<template>
    <div class="leaderboard-players-position">
        Your position: {{ position }}
    </div>
</template>

<script>
export default {
    name: 'LeaderboardPlayersPosition',
    props: {
        position: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style lang="scss">
.leaderboard-players-position {
    text-transform: uppercase;
    color: #ffc010;
    text-align: center;
    font: 500 24px/normal "Tektur";
    margin-bottom: 40px;

    margin-right: auto;
    width: fit-content;
}

@media screen and (max-width: 1023px) {
    .leaderboard-players-position {
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
    }
}

@media screen and (max-width: ($bp_mb - 1px)) {
    .leaderboard-players-position {
        font-size: 16px;
        line-height: 138%;
    }
}
</style>
