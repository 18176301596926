var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "event-info" }, [
    _c("div", { staticClass: "event-info__wrap" }, [
      _c("h2", { staticClass: "event-info__title" }, [
        _vm._v(_vm._s(_vm.event?.title)),
      ]),
      _c("div", { staticClass: "event-info__inner" }, [
        _c("div", { staticClass: "event-info__banner-wrap" }, [
          _c("img", {
            staticClass: "event-info__banner",
            attrs: { src: `${_vm.event?.banner}`, alt: "event banner" },
          }),
          _c("p", { staticClass: "event-info__text-block" }, [
            _vm._v(" " + _vm._s(_vm.event?.description) + " "),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "event-info__props-wrap" },
          [
            _c("EventTimer", {
              attrs: {
                time: _vm.time,
                finish: _vm.finish,
                start: _vm.start,
                currentDate: _vm.currentDate,
              },
            }),
            _c("ul", { staticClass: "event-info__props" }, [
              _c("li", { staticClass: "event-info__props-item" }, [
                _c("div", { staticClass: "event-info__props-name" }, [
                  _vm._v("Game Mode"),
                ]),
                _c("div", { staticClass: "event-info__props-value" }, [
                  _vm._v(_vm._s(_vm.event?.game_mode)),
                ]),
              ]),
              _c("li", { staticClass: "event-info__props-item" }, [
                _c("div", { staticClass: "event-info__props-name" }, [
                  _vm._v("NFT"),
                ]),
                _c("div", { staticClass: "event-info__props-value" }, [
                  _vm._v(_vm._s(_vm.event?.nft || "ALL FIGHTERS")),
                ]),
              ]),
              _c("li", { staticClass: "event-info__props-item" }, [
                _c("div", { staticClass: "event-info__props-name" }, [
                  _vm._v("Goal"),
                ]),
                _c("div", { staticClass: "event-info__props-value" }, [
                  _vm._v(_vm._s(_vm.event?.goal)),
                ]),
              ]),
              _c("li", { staticClass: "event-info__props-item" }, [
                _c("div", { staticClass: "event-info__props-name" }, [
                  _vm._v("Type"),
                ]),
                _c("div", { staticClass: "event-info__props-value" }, [
                  _vm._v(_vm._s(_vm.event?.game_type)),
                ]),
              ]),
              _c("li", { staticClass: "event-info__props-item" }, [
                _c("div", { staticClass: "event-info__props-name" }, [
                  _vm._v("Prize"),
                ]),
                _c("div", { staticClass: "event-info__props-value" }, [
                  _vm._v(
                    _vm._s(_vm.formattedPrize) +
                      " " +
                      _vm._s(_vm.event?.prize_currency)
                  ),
                ]),
              ]),
              _vm.event?.min_spot_wallet_amount
                ? _c("li", { staticClass: "event-info__props-item" }, [
                    _c("div", { staticClass: "event-info__props-name" }, [
                      _vm._v("Min Spot Wallet"),
                    ]),
                    _c("div", { staticClass: "event-info__props-value" }, [
                      _vm._v(_vm._s(_vm.event?.min_spot_wallet_amount)),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }