// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".leaderboard-players-position {\n  text-transform: uppercase;\n  color: #ffc010;\n  text-align: center;\n  font: 500 24px/normal \"Tektur\";\n  margin-bottom: 40px;\n  margin-right: auto;\n  width: -moz-fit-content;\n  width: fit-content;\n}\n@media screen and (max-width: 1023px) {\n.leaderboard-players-position {\n    margin-left: auto;\n    margin-right: auto;\n    font-size: 20px;\n}\n}\n@media screen and (max-width: 639px) {\n.leaderboard-players-position {\n    font-size: 16px;\n    line-height: 138%;\n}\n}", ""]);
// Exports
exports.locals = {
	"bp_mb_xs": "430px",
	"bp-mb": "640px",
	"bp-laptop": "1630px",
	"bp-desktop": "992px"
};
module.exports = exports;
