var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "leaderboard" }, [
    _c("main", { staticClass: "leaderboard__main container" }, [
      _c(
        "div",
        { staticClass: "leaderboard__header" },
        [
          _c("h1", { staticClass: "leaderboard__title" }, [
            _vm._v("Leaderboard"),
          ]),
          _c(
            "vue-slick-carousel",
            _vm._b(
              {
                staticClass: "leaderboard__nav",
                scopedSlots: _vm._u([
                  {
                    key: "prevArrow",
                    fn: function () {
                      return [
                        _c("BaseIcon", {
                          staticClass: "leaderboard__icon",
                          attrs: { name: "leaderboard-arrow" },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "nextArrow",
                    fn: function () {
                      return [
                        _c("BaseIcon", {
                          staticClass:
                            "leaderboard__icon leaderboard__icon--rotate",
                          attrs: { name: "leaderboard-arrow" },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              "vue-slick-carousel",
              _vm.getSliderSettings,
              false
            ),
            _vm._l(_vm.items, function (item, index) {
              return _c("LeaderboardNavigationItem", {
                key: index,
                attrs: {
                  text: item.text,
                  link: `/leaderboard/${item.link}`,
                  addClass: item.class,
                  btnBG: item.btnBG,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "leaderboard__wrap" }, [
        this.tab === "nft"
          ? _c(
              "div",
              { staticClass: "leaderboard__table" },
              [
                _vm.leaderboardNFT.nfts_position
                  ? _c("LeaderboardPlayersPositionNFT", {
                      attrs: { positions: _vm.leaderboardNFT.nfts_position },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  [
                    _vm.leaderboardNFT.leaderboard?.length
                      ? _c("LeaderboardTableNFT", {
                          attrs: {
                            rows: _vm.leaderboardNFT,
                            isLoading: _vm.isLoading,
                          },
                        })
                      : _c("p", { staticClass: "leaderboard__no-data" }, [
                          _vm._v(
                            "There are no players in the leaderboard yet. Hurry up to be the first!"
                          ),
                        ]),
                  ],
                  1
                ),
              ],
              1
            )
          : this.tab === "wallet"
          ? _c(
              "div",
              { staticClass: "leaderboard__table" },
              [
                _vm.leaderboardWallet.player_position
                  ? _c("LeaderboardPlayersPosition", {
                      attrs: {
                        position: _vm.leaderboardWallet.player_position,
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  [
                    _vm.leaderboardWallet.leaderboard?.length
                      ? _c("LeaderboardTable", {
                          attrs: {
                            rows: _vm.leaderboardWallet,
                            isLoading: _vm.isLoading,
                          },
                        })
                      : _c("p", { staticClass: "leaderboard__no-data" }, [
                          _vm._v(
                            "There are no players in the leaderboard yet. Hurry up to be the first!"
                          ),
                        ]),
                  ],
                  1
                ),
              ],
              1
            )
          : _c(
              "div",
              [
                _c("LeaderboardEvent", {
                  attrs: {
                    event: _vm.leaderboardEvent(this.tab),
                    rows: _vm.leaderboardEventTable,
                    isLoading: _vm.isLoading,
                  },
                }),
              ],
              1
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }