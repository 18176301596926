var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "leaderboard-event" }, [
    _c(
      "div",
      [
        _vm.rows?.leaderboard_type === "wallet" && _vm.rows.player_position
          ? _c("LeaderboardPlayersPosition", {
              attrs: { position: _vm.rows.player_position },
            })
          : _vm.rows?.leaderboard_type === "nft" && _vm.rows.nfts_position
          ? _c("LeaderboardPlayersPositionNFT", {
              attrs: { positions: _vm.rows.nfts_position },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "leaderboard-event__table-wrap" },
      [
        _vm.rows?.leaderboard?.length
          ? _c(
              "div",
              { staticClass: "leaderboard-event__table" },
              [
                _vm.rows?.leaderboard_type === "nft"
                  ? _c("LeaderboardTableNFT", {
                      attrs: { rows: _vm.rows, isLoading: _vm.isLoading },
                    })
                  : _c("LeaderboardTable", {
                      attrs: { rows: _vm.rows, isLoading: _vm.isLoading },
                    }),
              ],
              1
            )
          : _c("p", { staticClass: "leaderboard-event__no-data" }, [
              _vm._v(
                "There are no players in the leaderboard yet. Hurry up to be the first!"
              ),
            ]),
        _c("EventInfo", {
          staticClass: "leaderboard-event__info",
          attrs: { event: _vm.event },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }