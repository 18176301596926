var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      this.start >= 0
        ? _c("vue-countdown", {
            attrs: { time: _vm.start, transform: _vm.transformSlotProps },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function ({ days, hours, minutes, seconds }) {
                    return [
                      _c("div", { staticClass: "event-timer" }, [
                        _c("div", { staticClass: "event-timer__container" }, [
                          _c("div", { staticClass: "event-timer__text" }, [
                            _vm._v("Event starts in"),
                          ]),
                          _c("div", { staticClass: "event-timer__clock" }, [
                            _c("div", { staticClass: "event-timer__item" }, [
                              _c(
                                "div",
                                { staticClass: "event-timer__number" },
                                [_vm._v(_vm._s(days))]
                              ),
                              _c("div", { staticClass: "event-timer__unit" }, [
                                _vm._v("days"),
                              ]),
                            ]),
                            _c("div", { staticClass: "event-timer__colon" }, [
                              _vm._v(":"),
                            ]),
                            _c("div", { staticClass: "event-timer__item" }, [
                              _c(
                                "div",
                                { staticClass: "event-timer__number" },
                                [_vm._v(_vm._s(hours))]
                              ),
                              _c("div", { staticClass: "event-timer__unit" }, [
                                _vm._v("hrs"),
                              ]),
                            ]),
                            _c("div", { staticClass: "event-timer__colon" }, [
                              _vm._v(":"),
                            ]),
                            _c("div", { staticClass: "event-timer__item" }, [
                              _c(
                                "div",
                                { staticClass: "event-timer__number" },
                                [_vm._v(_vm._s(minutes))]
                              ),
                              _c("div", { staticClass: "event-timer__unit" }, [
                                _vm._v("mins"),
                              ]),
                            ]),
                            _c("div", { staticClass: "event-timer__colon" }, [
                              _vm._v(":"),
                            ]),
                            _c("div", { staticClass: "event-timer__item" }, [
                              _c(
                                "div",
                                { staticClass: "event-timer__number" },
                                [_vm._v(_vm._s(seconds))]
                              ),
                              _c("div", { staticClass: "event-timer__unit" }, [
                                _vm._v("secs"),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("div", { staticClass: "event-timer__progress" }, [
                          _c("div", {
                            staticClass: "event-timer__progress-value",
                            style: {
                              width: `${_vm.progressPercentBeforeStart}%`,
                            },
                          }),
                        ]),
                      ]),
                    ]
                  },
                },
              ],
              null,
              false,
              276610578
            ),
          })
        : this.currentDate > 0
        ? _c("p", { staticClass: "event-timer__expired" }, [
            _vm._v("The event has already ended"),
          ])
        : _c("vue-countdown", {
            attrs: { time: _vm.time, transform: _vm.transformSlotProps },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ days, hours, minutes, seconds }) {
                  return [
                    _c("div", { staticClass: "event-timer" }, [
                      _c("div", { staticClass: "event-timer__container" }, [
                        _c("div", { staticClass: "event-timer__text" }, [
                          _vm._v("Event ends in"),
                        ]),
                        _c("div", { staticClass: "event-timer__clock" }, [
                          _c("div", { staticClass: "event-timer__item" }, [
                            _c("div", { staticClass: "event-timer__number" }, [
                              _vm._v(_vm._s(days)),
                            ]),
                            _c("div", { staticClass: "event-timer__unit" }, [
                              _vm._v("days"),
                            ]),
                          ]),
                          _c("div", { staticClass: "event-timer__colon" }, [
                            _vm._v(":"),
                          ]),
                          _c("div", { staticClass: "event-timer__item" }, [
                            _c("div", { staticClass: "event-timer__number" }, [
                              _vm._v(_vm._s(hours)),
                            ]),
                            _c("div", { staticClass: "event-timer__unit" }, [
                              _vm._v("hrs"),
                            ]),
                          ]),
                          _c("div", { staticClass: "event-timer__colon" }, [
                            _vm._v(":"),
                          ]),
                          _c("div", { staticClass: "event-timer__item" }, [
                            _c("div", { staticClass: "event-timer__number" }, [
                              _vm._v(_vm._s(minutes)),
                            ]),
                            _c("div", { staticClass: "event-timer__unit" }, [
                              _vm._v("mins"),
                            ]),
                          ]),
                          _c("div", { staticClass: "event-timer__colon" }, [
                            _vm._v(":"),
                          ]),
                          _c("div", { staticClass: "event-timer__item" }, [
                            _c("div", { staticClass: "event-timer__number" }, [
                              _vm._v(_vm._s(seconds)),
                            ]),
                            _c("div", { staticClass: "event-timer__unit" }, [
                              _vm._v("secs"),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "event-timer__progress" }, [
                        _c("div", {
                          staticClass: "event-timer__progress-value",
                          style: {
                            width: `${_vm.progressPercentBeforeFinish}%`,
                          },
                        }),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }