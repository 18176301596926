<template>
    <div class="leaderboard-players-position-nft" @click="openModal" :class="{'active': isModalOpen}">
        <div class="leaderboard-players-position-nft__wrap">
            <p>Your position</p>
            <BaseIcon :name="'arrow-down'" class="leaderboard-players-position-nft__icon" />
        </div>
        <div class="leaderboard-players-position-nft__submenu">
            <div class="leaderboard-players-position-nft__submenu-inner">
                <template v-for="(position, index) in positions">
                    <div
                        :key="index"
                        class="leaderboard-players-position-nft__link"
                    >
                        <p>{{ position.name }}</p>
                        <p>{{ position.position }}</p>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LeaderboardPlayersPositionNFT',
    props: {
        positions: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            isModalOpen: false,
        }
    },
    methods: {
        openModal() {
            this.isModalOpen = !this.isModalOpen;
        },
    }
}
</script>

<style lang="scss" scoped>
.leaderboard-players-position-nft {
    position: relative;
    width: fit-content;

    &__wrap {
        display: flex;
        gap: 10px;
        align-items: center;
        width: fit-content;
        margin-bottom: 40px;
        margin-right: auto;

        text-transform: uppercase;
        color: #ffc010;
        text-align: center;
        font: 500 24px/normal "Tektur";

        cursor: pointer;
    }

    &__icon {
        width: 24px;
        height: 24px;
        transition: transform 300ms ease-in;
    }

    &__submenu {
        display: none;
        content: "";
        position: absolute;
        left: 0;
        top: 46px;
        flex-direction: column;
        min-width: 280px;
        padding: 12px 22px;
        z-index: 1;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.80) 20.16%, rgba(4, 9, 27, 0.80) 53.24%);
        backdrop-filter: blur(7.5px);
        border: 2px solid #FFC010;
        transform: none;
        transition: 300ms ease-in;

        max-height: 240px;
    }

    &__submenu-inner {
        overflow-x: auto;

        &::-webkit-scrollbar {
            height: 6px;
            width: 16px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-track {
            margin-block-start: 6px;
        }

        &::-webkit-scrollbar-corner {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-clip: padding-box;
            border-left: 10px solid transparent;
            background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
            background-repeat: no-repeat;
        }
    }

    &__link {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        font-size: 18px;
        font-weight: 500;
        line-height: normal;
        color: #ffffff;
        
        &:not(:last-child) {
            border-bottom: 1px solid rgba(191, 191, 191, 0.10);
        }
    }

    &.active {
        .leaderboard-players-position-nft__submenu {
            display: flex;
        }

        .leaderboard-players-position-nft__icon {
            transform: rotate(180deg);
        }
    }
}

@media screen and (max-width: 1023px) {
    .leaderboard-players-position-nft {
        margin: 0 auto;

        &__wrap {
            font-size: 16px;
            line-height: 138%;
        }

        &__submenu {
            left: 50%;
            min-width: 260px;
            transform: translateX(-50%);

            max-height: 230px;
        }

        &__link {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: ($bp_mb - 1px)) {
    .leaderboard-players-position-nft {
        &__wrap {
            gap: 6px;
        }

        &__submenu {
            padding: 8px 14px;
            min-width: 210px;

            max-height: 170px;
        }

        &__link {
            padding: 6px 0;
            font-size: 14px;
        }
    }
}
</style>
