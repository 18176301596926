<template>
    <div>
        <vue-countdown v-if="this.start >= 0" :time="start" :transform="transformSlotProps" v-slot="{ days, hours, minutes, seconds }">
            <div class="event-timer">
                <div class="event-timer__container">
                    <div class="event-timer__text">Event starts in</div>
                    <div class="event-timer__clock">
                        <div class="event-timer__item">
                            <div class="event-timer__number">{{ days }}</div>
                            <div class="event-timer__unit">days</div>
                        </div>
                        <div class="event-timer__colon">:</div>
                        <div class="event-timer__item">
                            <div class="event-timer__number">{{ hours }}</div>
                            <div class="event-timer__unit">hrs</div>
                        </div>
                        <div class="event-timer__colon">:</div>
                        <div class="event-timer__item">
                            <div class="event-timer__number">{{ minutes }}</div>
                            <div class="event-timer__unit">mins</div>
                        </div>
                        <div class="event-timer__colon">:</div>
                        <div class="event-timer__item">
                            <div class="event-timer__number">{{ seconds }}</div>
                            <div class="event-timer__unit">secs</div>
                        </div>
                    </div>
                </div>
                <div class="event-timer__progress">
                    <div class="event-timer__progress-value" :style="{width: `${progressPercentBeforeStart}%`}"></div>
                </div>
            </div>
        </vue-countdown>
        <p v-else-if="this.currentDate > 0" class="event-timer__expired">The event has already ended</p>
        <vue-countdown v-else :time="time" :transform="transformSlotProps" v-slot="{ days, hours, minutes, seconds }">
            <div class="event-timer">
                <div class="event-timer__container">
                    <div class="event-timer__text">Event ends in</div>
                    <div class="event-timer__clock">
                        <div class="event-timer__item">
                            <div class="event-timer__number">{{ days }}</div>
                            <div class="event-timer__unit">days</div>
                        </div>
                        <div class="event-timer__colon">:</div>
                        <div class="event-timer__item">
                            <div class="event-timer__number">{{ hours }}</div>
                            <div class="event-timer__unit">hrs</div>
                        </div>
                        <div class="event-timer__colon">:</div>
                        <div class="event-timer__item">
                            <div class="event-timer__number">{{ minutes }}</div>
                            <div class="event-timer__unit">mins</div>
                        </div>
                        <div class="event-timer__colon">:</div>
                        <div class="event-timer__item">
                            <div class="event-timer__number">{{ seconds }}</div>
                            <div class="event-timer__unit">secs</div>
                        </div>
                    </div>
                </div>
                <div class="event-timer__progress">
                    <div class="event-timer__progress-value" :style="{width: `${progressPercentBeforeFinish}%`}"></div>
                </div>
            </div>
        </vue-countdown>
    </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
    components: {
        VueCountdown
    },
    props: {
        time: {
            type: Number,
        },
        finish: {
            type: Number,
        },
        start: {
            type: Number,
        },
        currentDate: {
            type: Number,
        }
    },
    methods: {
        transformSlotProps(props) {
        const formattedProps = {};

        Object.entries(props).forEach(([key, value]) => {
            formattedProps[key] = value < 10 ? `0${value}` : String(value);
        });

        return formattedProps;
        },
    },
    computed: {
        progressPercentBeforeFinish() {
            const remainingPercent = (this.finish - this.time) / this.finish * 100;

            return remainingPercent > 100 ? 100 : remainingPercent;
        },
        progressPercentBeforeStart() {
            const remainingPercent = (this.finish - this.start) / this.finish * 100;

            return remainingPercent > 100 ? 100 : remainingPercent;
        },
    }
}
</script>

<style lang="scss" scoped>
.event-timer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    background-color: #04091B;
    border: 1px solid #FFC010;
}

.event-timer__container {
    color: white;
    display: flex;
    align-items: center;
}

.event-timer__text {
    flex: 1;
    font: 500 22px/normal "Tektur";
    padding: 10px;
    border-right: 1px solid #FFC010;
}

.event-timer__clock {
    display: flex;
    gap: 8px;
    padding: 8px 20px;
}

.event-timer__number {
    font: 500 22px/normal "Tektur";
    text-align: center;
}

.event-timer__unit {
    font: 500 18px/normal "Tektur";
    text-align: center;
    color: #BFBFBF;
}

.event-timer__colon {
    font: 500 16px/normal "Tektur";
}

.event-timer__progress {
    margin: 0 10px 10px;
    position: relative;
    height: 8px;
    background-color: rgba(41, 46, 61, 0.5);
}

.event-timer__progress-value {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 22%;
    background: linear-gradient(to bottom, #FFCC13 0, #FF9509 100%);
}

.event-timer__expired {
    color: white;
    font-family: "Tektur";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
}

@media screen and (max-width: 1439px) {
    .event-timer__text {
        font-size: 18px;
    }

    .event-timer__number {
        font-size: 18px;
    }

    .event-timer__unit {
        font-size: 16px;
    }
}

@media screen and (max-width: 1279px) {
    .event-timer__text {
        font-size: 16px;
        padding: 13px;
        height: unset;
    }

    .event-timer__number {
        font-size: 16px;
    }

    .event-timer__unit {
        font-size: 12px;
    }
}

@media screen and (max-width: 1023px) {
    .event-timer__text {
        font-size: 14px;
        padding: 10px;
    }

    .event-timer__clock {
        gap: 0;
        padding: 8px 12px;
        flex-grow: 1;
    }

    .event-timer__item {
        flex-grow: 1;
    }

    .event-timer__text {
        min-width: fit-content;
        padding: 10px 12px 10px 10px;
    }

    .event-timer__expired {
        font-size: 18px;
    }
}

@media screen and (max-width: ($bp_mb - 1px)) {
    .event-timer__text {
        padding: 10px;
        font-size: 10px;
    }
}
</style>
