<template>
    <div class="leaderboard">
        <main class="leaderboard__main container">
            <div class="leaderboard__header">
                <h1 class="leaderboard__title">Leaderboard</h1>
                <vue-slick-carousel v-bind="getSliderSettings" class="leaderboard__nav">
                    <LeaderboardNavigationItem
                        v-for="(item, index) in items"
                        :key="index"
                        :text="item.text"
                        :link="`/leaderboard/${item.link}`"
                        :addClass="item.class"
                        :btnBG="item.btnBG"
                    />
                    <template #prevArrow>
                        <BaseIcon :name="'leaderboard-arrow'" class="leaderboard__icon"/>
                    </template>
                    <template #nextArrow>
                        <BaseIcon :name="'leaderboard-arrow'" class="leaderboard__icon leaderboard__icon--rotate"/>
                    </template>
                </vue-slick-carousel>
            </div>
            <div class="leaderboard__wrap">
                <div v-if="this.tab === 'nft'" class="leaderboard__table">
                    <LeaderboardPlayersPositionNFT v-if="leaderboardNFT.nfts_position" :positions="leaderboardNFT.nfts_position"/>
                    <div>
                        <LeaderboardTableNFT v-if="leaderboardNFT.leaderboard?.length" :rows="leaderboardNFT" :isLoading="isLoading" />
                        <p v-else class="leaderboard__no-data">There are no players in the leaderboard yet. Hurry up to be the first!</p>
                    </div>
                </div>
                <div v-else-if="this.tab === 'wallet'" class="leaderboard__table">
                    <LeaderboardPlayersPosition v-if="leaderboardWallet.player_position" :position="leaderboardWallet.player_position"/>
                    <div>
                        <LeaderboardTable v-if="leaderboardWallet.leaderboard?.length" :rows="leaderboardWallet" :isLoading="isLoading" />
                        <p v-else class="leaderboard__no-data">There are no players in the leaderboard yet. Hurry up to be the first!</p>
                    </div>
                </div>
                <div v-else>
                    <LeaderboardEvent :event="leaderboardEvent(this.tab)" :rows="leaderboardEventTable" :isLoading="isLoading" />
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import LeaderboardPlayersPositionNFT from '@/components/leaderboard/LeaderboardPlayersPositionNFT.vue';
import LeaderboardPlayersPosition from '@/components/leaderboard/LeaderboardPlayersPosition.vue';
import LeaderboardTableNFT from '@/components/leaderboard/LeaderboardTableNFT.vue';
import LeaderboardTable from '@/components/leaderboard/LeaderboardTable.vue';
import LeaderboardEvent from '@/components/leaderboard/LeaderboardEvent.vue';
import LeaderboardNavigationItem from '@/components/leaderboard/LeaderboardNavigationItem.vue';
import { mapActions, mapGetters } from "vuex";

export default {
    name: 'Leaderboard',
    components: {
        LeaderboardPlayersPositionNFT,
        LeaderboardPlayersPosition,
        LeaderboardTableNFT,
        LeaderboardTable,
        LeaderboardEvent,
        LeaderboardNavigationItem
    },
    computed: {
        ...mapGetters('leaderboards', ['leaderboardNFT', 'leaderboardWallet', 'leaderboardEvents', 'leaderboardEventTable', 'leaderboardEvent', 'isLoading']),
        tab () {
            return this.$route.params.tab
        },
        getSliderSettings() {
            return {
                arrows: false,
                dots: false,
                slidesToShow: this.items.length,
                infinite: false,
                responsive: [
                    {
                        "breakpoint": 1024,
                        "settings": {
                            "slidesToShow": 3,
                            "slidesToScroll": 3,
                            "arrows": true,
                        }
                    },
                    {
                        "breakpoint": 639,
                        "settings": {
                            "slidesToShow": 2,
                            "slidesToScroll": 2,
                            "arrows": true,
                        }
                    },
                    
                ]
            }
        },
        items() {
            if (this.leaderboardEvents.game_events?.length) {
                return [
                    {
                        text: 'wallet',
                        link: 'wallet',
                    },
                    {
                        text: 'nft',
                        link: 'nft'
                    },
                    ...this.leaderboardEvents.game_events.map(element => ({
                        text: element.button_title,
                        link: 'event/' + element.id,
                        btnBG: element.button_background,
                        class: 'leaderboard__nav-link--event'
                    }))
                ]
            } else {
                return [
                    {
                        text: 'wallet',
                        link: 'wallet',
                    },
                    {
                        text: 'nft',
                        link: 'nft'
                    },
                ]
            }
        },
    },
    methods: {
        ...mapActions('leaderboards', ['getLeaderboardWallet', 'getLeaderboardNFT', 'getLeaderboardEvents', 'getLeaderboardEventTable']),
    },
    mounted() {
        this.getLeaderboardEvents();
    },
    watch: {
        tab: {
            handler(value) {
                if (value === 'nft') {
                    this.getLeaderboardNFT();
                } else if (value === 'wallet') {
                    this.getLeaderboardWallet();
                } else {
                    this.getLeaderboardEventTable(value);
                }
            },
            immediate: true 
        },
    }
}
</script>

<style lang="scss" scoped>
.leaderboard__icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    background: none;
}

.leaderboard {
    position: relative;
    background-position: center top;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: url('../../assets/img/leaderboard-bg.png');
    background-size: 400% auto;
    background-repeat: no-repeat;

    &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(254deg, #020616 32.5%, #4C53A7 51.05%, #444B98 58.31%, #020616 71.23%);
        opacity: 0.95;
        mix-blend-mode: multiply;
        z-index: 0;
    }
}

.leaderboard__main {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 260px;
}

.leaderboard__header {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    align-items: center;
}

.leaderboard__title {
    text-transform: uppercase;
    color: white;
    font: 700 30px/normal "Tektur";
    text-align: center;
}

.leaderboard__wrap {
    width: 100%;
}

.leaderboard__no-data {
    font-family: "Tektur";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    color: #ffffff;
}

@media screen and (max-width: ($bp_desktop - 1px)) {
    .leaderboard__header {
        gap: 24px;
        margin-bottom: 24px;
    }

    .leaderboard__title {
        font-size: 25px;
    }

    .leaderboard__no-data {
        font-size: 18px;
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (max-width: ($bp_mb - 1px)) {
    .leaderboard__main {
        padding: 40px 0 100px;
    }

    .leaderboard__header {
        gap: 20px;
        margin-bottom: 20px;
    }

    .leaderboard__title {
        font-size: 18px;
        line-height: 123%;
    }

    .leaderboard__no-data {
        font-size: 16px;
        padding-left: 15px;
        padding-right: 15px;
    }
}
</style>
