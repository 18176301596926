import { render, staticRenderFns } from "./LeaderboardTable.vue?vue&type=template&id=ee498d5a&scoped=true&"
import script from "./LeaderboardTable.vue?vue&type=script&lang=js&"
export * from "./LeaderboardTable.vue?vue&type=script&lang=js&"
import style0 from "./LeaderboardTable.vue?vue&type=style&index=0&id=ee498d5a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee498d5a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/fruktorum/frontend/meta-fighter/meta-fighter/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ee498d5a')) {
      api.createRecord('ee498d5a', component.options)
    } else {
      api.reload('ee498d5a', component.options)
    }
    module.hot.accept("./LeaderboardTable.vue?vue&type=template&id=ee498d5a&scoped=true&", function () {
      api.rerender('ee498d5a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/leaderboard/LeaderboardTable.vue"
export default component.exports