var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "leaderboard-players-position-nft",
      class: { active: _vm.isModalOpen },
      on: { click: _vm.openModal },
    },
    [
      _c(
        "div",
        { staticClass: "leaderboard-players-position-nft__wrap" },
        [
          _c("p", [_vm._v("Your position")]),
          _c("BaseIcon", {
            staticClass: "leaderboard-players-position-nft__icon",
            attrs: { name: "arrow-down" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "leaderboard-players-position-nft__submenu" }, [
        _c(
          "div",
          { staticClass: "leaderboard-players-position-nft__submenu-inner" },
          [
            _vm._l(_vm.positions, function (position, index) {
              return [
                _c(
                  "div",
                  {
                    key: index,
                    staticClass: "leaderboard-players-position-nft__link",
                  },
                  [
                    _c("p", [_vm._v(_vm._s(position.name))]),
                    _c("p", [_vm._v(_vm._s(position.position))]),
                  ]
                ),
              ]
            }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }