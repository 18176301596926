<template>
    <div class="leaderboard__nav-item">
        <router-link
            :to="link"
            class="leaderboard__nav-link"
            :class="addClass"
            activeClass="leaderboard__nav-link--active"
            :style="styleObject"
        >
            {{ text }}
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        text: String,
        link: String,
        active: Boolean,
        addClass: {
            type: String,
            default: ''
        },
        btnBG: {
            type: String,
            default: ''
        }
    },
    computed: {
        styleObject() {
            if (this.btnBG) {
                return {
                    '--background': `url(${this.btnBG})`,
                    '--background-hover': `url(${this.btnBG}), #15267D`
                }
            }

            return {
                '--background': `transparent`,
                '--background-hover': `#15267D`
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.leaderboard__nav-link {
    width: 100%;
    min-width: 120px;
    min-height: 54px;
    background: transparent;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 5px;
    color: white;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
    text-transform: uppercase;
    font: 500 17px/normal "Tektur";

    &:hover {
        background: white;
        color: black;
    }

    &--event {
        background: var(--background);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        &:hover {
            background: var(--background-hover);
            background-blend-mode: lighten;
            color: white;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
    }
}

.leaderboard__nav-link--active {
    background: white;
    color: black;

    &.leaderboard__nav-link--event {
        background: var(--background-hover);
        background-blend-mode: lighten;
        color: white;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
}

@media screen and (max-width: ($bp_desktop - 1px)) {
    .leaderboard {
        &__nav-link {
            min-width: unset;
            max-width: unset;
        }
    }
}

@media screen and (max-width: ($bp_mb - 1px)) {
    .leaderboard {
        &__nav-link {
            width: 100%;
            min-width: 130px;
            font-size: 15px;
        }
    }
}
</style>
