<template>
    <div class="event-info">
        <div class="event-info__wrap">
            <h2 class="event-info__title">{{ event?.title }}</h2>
            <div class="event-info__inner">
                <div class="event-info__banner-wrap">
                    <img class="event-info__banner" :src='`${event?.banner}`' alt="event banner">
                    <p class="event-info__text-block">
                        {{ event?.description }}
                    </p>
                </div>
                <div class="event-info__props-wrap">
                    <EventTimer :time="time" :finish="finish" :start="start" :currentDate="currentDate"/>
                    <ul class="event-info__props">
                        <li class="event-info__props-item">
                            <div class="event-info__props-name">Game Mode</div>
                            <div class="event-info__props-value">{{ event?.game_mode }}</div>
                        </li>
                        <li class="event-info__props-item">
                            <div class="event-info__props-name">NFT</div>
                            <div class="event-info__props-value">{{ event?.nft || 'ALL FIGHTERS' }}</div>
                        </li>
                        <li class="event-info__props-item">
                            <div class="event-info__props-name">Goal</div>
                            <div class="event-info__props-value">{{ event?.goal }}</div>
                        </li>
                        <li class="event-info__props-item">
                            <div class="event-info__props-name">Type</div>
                            <div class="event-info__props-value">{{ event?.game_type }}</div>
                        </li>
                        <li class="event-info__props-item">
                            <div class="event-info__props-name">Prize</div>
                            <div class="event-info__props-value">{{ formattedPrize }} {{ event?.prize_currency }}</div>
                        </li>
                        <li v-if="event?.min_spot_wallet_amount" class="event-info__props-item">
                            <div class="event-info__props-name">Min Spot Wallet</div>
                            <div class="event-info__props-value">{{ event?.min_spot_wallet_amount }}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventTimer from '../events/EventTimer.vue';


export default {
    components: {
        EventTimer,
    },
    props: {
        event: {
            type: Object,
            default: () => {}
        },
    },
    computed: {
        time() {
            if (this.event) {
                return new Date(this.event.end_at) - new Date();
            } else {
                return 0;
            }
        },
        finish() {
            if (this.event) {
                return new Date(this.event.end_at) - new Date(this.event.start_at);
            } else {
                return 0;
            }
        },
        start() {
            if (this.event) {
                return new Date(this.event.start_at) - new Date();
            } else {
                return 0;
            }
        },
        formattedPrize() {
            return this.toLocaleFormat(this.event?.prize_amount.toFixed(2));
        },
        currentDate() {
            if (this.event) {
                return new Date() - new Date(this.event.end_at);
            } else {
                return 0;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.event-info {
    position: relative;
    padding: 70px 120px;
}

.event-info::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    backdrop-filter: blur(7px);
    background-color: rgba(255, 255, 255, 0.15);
    box-shadow: 3px 4px 4px 0px rgba(255, 255, 255, 0.20) inset,
        0px 4px 4px rgba(0, 0, 0, 0.25),
        0px 1px 4px rgba(0, 55, 96, 0.15);
    border-radius: 5px;
}

.event-info__wrap {
    display: flex;
    flex-direction: column;
    gap: 28px;
    align-items: center;
}

.event-info__title {
    color: #FFC010;
    font: 700 34px/normal "Tektur";
    text-transform: uppercase;
}

.event-info__banner-wrap {
    display: flex;
    flex-direction: column;
    gap: 25px;
    flex-basis: 50%;
}

.event-info__inner {
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.event-info__banner {
    border: 2px solid white;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.event-info__text-block {
    font: 500 22px/130% "Raleway";
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.event-info__highlight {
    font: 700 16px 21px "Raleway";
    color: #FFC010;
    text-transform: uppercase;
}

.event-info__link {
    font: 700 16px 21px "Raleway";
    color: #FFC010;
    text-decoration: underline;
    cursor: pointer;
}

.event-info__props-wrap {
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex-basis: 45%;
}

.event-info__props {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: white;
    font: 500 14px/normal "Tektur";
}

.event-info__props-item {
    background-color: #04091B;
    display: flex;
    align-items: center;
    min-height: 56px;
}

.event-info__props-name {
    display: flex;
    align-items: center;
    flex-basis: 44%;
    padding: 8px 10px;
    font-family: "Tektur";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    height: 100%;
}

.event-info__props-value {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 7px 20px;
    font-family: "Tektur";
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    border: 1px solid #FFCC13;
    height: 100%;
}

@media screen and (max-width: 1439px) {
    .event-info {
        padding: 60px 110px;
    }

    .event-info__props {
        gap: 10px;
    }
    
    .event-info__title {
        font-size: 28px;
    }

    .event-info__props-name {
        font-size: 18px;
    }

    .event-info__props-value {
       font-size: 20px; 
    }

    .event-info__text-block {
        font-size: 18px;
    }

    .event-info__props-item {
        min-height: 50px;
    }
}

@media screen and (max-width: 1279px) {
    .event-info {
        padding: 40px 90px;
    }

    .event-info__props {
        gap: 8px;
    }

    .event-info__title {
        font-size: 25px;
    }

    .event-info__props-item {
        min-height: unset;
    }

    .event-info__props-name {
        font-size: 16px;
    }

    .event-info__props-value {
       font-size: 18px; 
    }

    .event-info__text-block {
        font-size: 16px;
    }
}

@media screen and (max-width: 1023px) {
    .event-info {
        padding: 30px 24px;
        max-width: unset;

        &__wrap {
            gap: 20px;
        }

        &__inner {
            display: flex;
            gap: 30px;
            max-width: unset;
        }

        &__banner-wrap {
            flex-basis: 52%;
        }

        &__banner-wrap {
            gap: 15px;
        }

        &__text-block {
            text-align: left;
        }

        &__props-wrap {
            display: flex;
            flex-direction: column;
            gap: 20px;
            flex-basis: 55%;
        }

        &__props-item {
            margin: 0;
        }

        &__props-name {
            font-size: 14px;
            padding: 12px 10px;
        }

        &__props-value {
            font-size: 16px;
            padding: 10px 15px;
        }
    }
}

@media screen and (max-width: ($bp_mb - 1px)) {
    .event-info {
        padding: 24px 27px;

        &__title {
            font-size: 18px;
            line-height: 123%;
        }

        &__inner {
            gap: 20px;
            flex-direction: column;
        }

        &__text-block {
            text-align: center;
        }
    }
}
</style>
