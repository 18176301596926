var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "leaderboard__nav-item" },
    [
      _c(
        "router-link",
        {
          staticClass: "leaderboard__nav-link",
          class: _vm.addClass,
          style: _vm.styleObject,
          attrs: { to: _vm.link, activeClass: "leaderboard__nav-link--active" },
        },
        [_vm._v(" " + _vm._s(_vm.text) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }