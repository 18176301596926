<template>
    <div class="leaderboard-table__container">
        <BaseIcon :name="'leaderboard-arrow'" class="leaderboard-table__icon" v-show="isRightArrowVisible"/>
        <BaseIcon :name="'leaderboard-arrow'" class="leaderboard-table__icon leaderboard-table__icon--rotate" v-show="isLeftArrowVisible"/>
        <AnimatedBorderBlock class="leaderboard-table__wrapper">
            <table class="leaderboard-table">
                <thead class="leaderboard-table__head">
                    <tr class="leaderboard-table__header-row">
                        <th class="leaderboard-table__header-cell leaderboard-table__header-cell--first"></th>
                        <th class="leaderboard-table__header-cell">
                            Fights
                        </th>
                        <th class="leaderboard-table__header-cell">
                            Win
                        </th>
                        <th class="leaderboard-table__header-cell">
                            Loss
                        </th>
                        <th class="leaderboard-table__header-cell">
                            WinRate
                        </th>
                        <th class="leaderboard-table__header-cell">
                            XP (f2g)
                        </th>
                    </tr>
                </thead>
                <tbody class="leaderboard-table__body">
                    <tr
                        v-for="(row, index) in (isLoading ? [] : rows.leaderboard)"
                        :key="index"
                        class="leaderboard-table__row"
                    >
                        <th class="leaderboard-table__cell leaderboard-table__cell--header">
                            <div class="leaderboard-table__place">{{ index + 1 }}</div>
                            <p class="leaderboard-table__cell-text leaderboard-table__cell-text--user" v-if="isFullWidth">{{ row.wallet }}</p>
                            <p class="leaderboard-table__cell-text leaderboard-table__cell-text--user" v-else>{{ hideWalletName(row.wallet) }}</p>
                        </th>
                        <td class="leaderboard-table__cell"><span class="leaderboard-table__cell-text">{{ row.fights }}</span></td>
                        <td class="leaderboard-table__cell"><span class="leaderboard-table__cell-text">{{ row.win }}</span></td>
                        <td class="leaderboard-table__cell"><span class="leaderboard-table__cell-text">{{ row.loss }}</span></td>
                        <td class="leaderboard-table__cell"><span class="leaderboard-table__cell-text">{{ row.win_rate }}%</span></td>
                        <td class="leaderboard-table__cell">
                            <div class="leaderboard-table__xp"><span class="leaderboard-table__cell-text">{{ row.total_xp }}</span></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </AnimatedBorderBlock>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AnimatedBorderBlock from '@/components/common/animated-border-block.vue';

export default {
    name: 'LeaderboardTable',
    components: {
        AnimatedBorderBlock
    },
    props: {
        rows: {
            type: Object,
            default: () => {}
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isRightArrowVisible: false,
            isLeftArrowVisible: false,
        }
    },
    mounted() {
        const table = document.querySelector('.leaderboard-table__wrapper');
        table.addEventListener('scroll', this.actionScroll);
        window.addEventListener('load', this.scrollbarVisible);
        window.addEventListener('resize', this.scrollbarVisible);
    },
    methods: {
        actionScroll () {
            const table = document.querySelector('.leaderboard-table__wrapper');
            if (table.scrollWidth > table.clientWidth) {
                if (table.scrollLeft === 0) {
                    this.isRightArrowVisible = true;
                    this.isLeftArrowVisible = false;
                } else if (table.scrollLeft > 0 && table.scrollLeft < (table.scrollWidth - table.clientWidth)) {
                    this.isRightArrowVisible = true;
                    this.isLeftArrowVisible = true;
                } else {
                    this.isLeftArrowVisible = true;
                    this.isRightArrowVisible = false;
                }
                if (table.scrollTop !== 0) {
                    this.isRightArrowVisible = false;
                    this.isLeftArrowVisible = false;
                }
            }
        },
        scrollbarVisible () {
            const table = document.querySelector('.leaderboard-table__wrapper');
            if (table.scrollWidth > table.clientWidth) {
                this.isRightArrowVisible = true;
            } else {
                this.isRightArrowVisible = false;
                this.isLeftArrowVisible = false;
            }
        },
        hideWalletName(value) {
            return value.slice(0, 6) + '...' + value.slice(-4);
        }
    },
    computed: {
        ...mapGetters([
            'GET_IS_MOBILE',
            'GET_WINDOW_WIDTH',
        ]),
        isFullWidth() {
            return this.GET_WINDOW_WIDTH > 1279;
        },
    }
}
</script>

<style lang="scss" scoped>
.leaderboard-table__container {
    position: relative;
    padding: 40px;
    max-width: 100%;
    max-height: 820px;
    background-color: rgba(255, 255, 255, 0.10);
    box-shadow: 3px 4px 4px 0px rgba(255, 255, 255, 0.20) inset,
        0px 4px 4px rgba(0, 0, 0, 0.25),
        0px 1px 4px rgba(0, 55, 96, 0.15);
    backdrop-filter: blur(7.5px);
    border-radius: 5px;
    margin: 0 auto;
}

.leaderboard-table__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: calc(820px - 130px);
    z-index: 1;
    overflow-y: auto;
    overflow-x: auto;

    &::-webkit-scrollbar {
        height: 6px;
        width: 26px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar-track {
        margin-block-start: 52px;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        border-left: 20px solid transparent;
        background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
        background-repeat: no-repeat;
    }

    &::-webkit-scrollbar:horizontal {
        height: 26px;
        width: 6px;
    }

    &::-webkit-scrollbar-track:horizontal {
        margin-block-start: 0;
    }

    &::-webkit-scrollbar-thumb:horizontal {
        border-left: 0;
        background-clip: padding-box;
        border-top: 20px solid transparent;
        background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
        background-repeat: no-repeat;
    }
}

.leaderboard-table__icon {
    position: absolute;
    top: 57px;
    right: 34px;
    z-index: 5;

    &--rotate {
        right: unset;
        left: 18px;
        transform: rotate(180deg);
    }
}

.leaderboard-table {
    width: 100%;
    height: 100%;
    color: white;
    border-collapse: separate;
    border-spacing: 0 5px;
}

.leaderboard-table__header-row {
    font: 500 17px/130% "Tektur";
}

.leaderboard-table__header-cell {
    position: sticky;
    top: 0;
    z-index: 2;
    text-align: center;
    height: 42px;
    min-width: 140px;
    text-transform: uppercase;
    padding: 10px;
    background-color: #282728;

    &--first {
        left: 0;
        z-index: 3;
    }
}

.leaderboard-table__row {
    font: 500 18px/130% "Tektur";
    background: rgba(41, 46, 61, 0.50);

    &:nth-child(-n+3) {
        .leaderboard-table__cell-text {
            background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        & .leaderboard-table__place {
            font-size: 16px;
            height: 36px;
            background: url("../../assets/svg/trophey.svg?inline") center no-repeat;
            color: black;
        }
    }

    &:nth-child(1) {
        background: rgba(21, 38, 125, 0.6);
    }

    &:nth-child(2) {
        background: rgba(21, 38, 125, 0.4);
    }
    
    &:nth-child(3) {
        background: rgba(21, 38, 125, 0.2);
    }
}

.leaderboard-table__cell {
    text-align: center;
    min-width: 140px;
    padding: 18px 10px;
    align-self: center;
    font-family: "Tektur";
    font-size: 18px;
    line-height: normal;

    &--header {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 12px;
        min-width: 300px;
        height: 100%;
        flex-grow: 1;
        position: sticky;
        left: 0;
        z-index: 2;
        background-color: #282728;
    }
}

.leaderboard-table__place {
    height: fit-content;
    width: 36px;
    font-family: "Tektur";
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
}

.leaderboard-table__xp {
    display: flex;
    gap: 10px;
    align-items: center;
}

.leaderboard-table__xp::before {
    content: '';
    background: url('../../assets/svg/xp.svg?inline') center/contain;
    height: 21px;
    width: 22px;
    background-repeat: no-repeat;
}

@media screen and (max-width: 1820px) {
    .leaderboard-table {
        &__header-cell {
            min-width: 130px;
        }

        &__cell {
            min-width: 130px;

            &--header {
                min-width: 290px;
            }
        }
    }
}

@media screen and (max-width: 1679px) {
    .leaderboard-table {
        &__container {
            padding: 30px 55px 30px 30px;
        }

        &__header-cell {
            min-width: 115px;
        }

        &__cell {
            min-width: 115px;

            &--header {
                min-width: 280px;
            }
        }

        &__icon {
            top: 45px;
            right: 48px;

            &--rotate {
                left: 8px;
                right: unset;
            }
        }
    }
}

@media screen and (max-width: 1279px) {
    .leaderboard-table {
        &__wrapper {
            &::-webkit-scrollbar {
                width: 22px;
            }

            &::-webkit-scrollbar-thumb {
                border-left: 16px solid transparent;
            }

            &::-webkit-scrollbar:horizontal {
                height: 22px;
                width: 6px;
            }

            &::-webkit-scrollbar-track:horizontal {
                margin-block-start: 0;
            }

            &::-webkit-scrollbar-thumb:horizontal {
                border-left: 0;
                background-clip: padding-box;
                border-top: 16px solid transparent;
                background: linear-gradient(90deg, #FF9509 0%, #FFCC13 100%);
                background-repeat: no-repeat;
            }
        }

        &__wrapper {
            overflow-x: auto;
            max-width: calc(100vw - 106px);
            max-height: calc(665px - 75px);
        }

        &__container {
            max-height: 655px;
            padding: 24px;
        }

        &__header-cell {
            min-width: 105px;
        }

        &__cell {
            min-width: 105px;
            font-size: 16px;
            padding: 14px 10px;

            &--header {
                min-width: fit-content;
                height: 100%;
                padding: 10px;
            }
        }

        &__place {
            width: 30px;
        }

        &__row {
            min-height: 50px;

            &:nth-child(-n+3) {
                & .leaderboard-table__place {
                    height: 30px;
                }
            }
        }

        &__icon {
            top: 41px;
            right: 18px;

            &--rotate {
                left: 5px;
                right: unset;
            }
        }
    }
}

@media screen and (max-width: ($bp_desktop - 1px)) {
    .leaderboard-table {
        &__place {
            font-size: 14px;
        }

        &__row {
            &:nth-child(-n+3) {
                & .leaderboard-table__place {
                    font-size: 14px;
                }
            }
        }

        &__icon {
            right: 45px;
            top: 39px;

            &--rotate {
                right: unset;
            }
        }
    }
}

@media screen and (max-width: ($bp_mb - 1px)) {
    .leaderboard-table {
        &__wrapper {
            max-width: calc(100vw - 40px);
            max-height: calc(655px - 47px);

            &::-webkit-scrollbar {
                width: 20px;
            }

            &::-webkit-scrollbar-thumb {
                border-left: 14px solid transparent;
            }

            &::-webkit-scrollbar:horizontal {
                height: 20px;
            }

            &::-webkit-scrollbar-thumb:horizontal {
                border-top: 14px solid transparent;
            }
        }

        &__container {
            padding: 24px 20px;
        }

        &__cell {
            min-width: 82px;
            font-size: 14px;

            &--header {
                padding: 7px 10px;
                width: 100%;
                min-width: fit-content;
            }
        }

        &__header-cell {
            min-width: 82px;
            font-size: 15px;
        }

        &__row {
            &:nth-child(-n+3) {
                & .leaderboard-table__place {
                    height: 27px;
                }
            }
        }

        &__cell-text {
            &--user {
                padding-top: 40px;
            }
        }

        &__place {
            position: absolute;
            top: 10px;
            width: 28px;
        }

        &__icon {
            right: 14px;

            &--rotate {
                left: 0;
                right: unset;
            }
        }
    }
}
</style>
